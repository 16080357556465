import { useState } from "react";

export const usePagination = startingPage => {
  const [currentPage, setCurrentPage] = useState(startingPage);

  const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage - 1);

  return { currentPage, setCurrentPage, nextPage, prevPage };
};
