import React, { useEffect } from "react";
import {
  Col, Container, Row
} from "react-bootstrap";
import slugify from "slugify";
import { NumberParam, useQueryParams } from "use-query-params";
import { renderDynamicComponents } from "../components";
import Button from "../components/button";
import PaginationButtons from "../components/paginationButtons";
import TeaserCard from "../components/teaserCard";
import { getValue } from "../helper/translation";
import { usePagination } from "../hooks/usePagination";
import Breadcrumb from "../modules/breadcrumb";
import Layout from "../modules/layout";
import SEO from "../modules/seo";

const SLUGIFY_OPTIONS = {
  replacement: "-", // replace spaces with replacement
  remove: /[^A-Za-z\-\_0-9 ]/, // regex to remove characters
  lower: true, // result in lower case
};

const NewsletterOverview = ({ data: { allNewsletter }, pageContext }) => {
  const { name, contentComponents, node_locale, settings, breadcrumbs, showBreadcrumbNavigation, showNews } = pageContext;
  const siteSettings = settings;
  const { microcopy } = siteSettings
  allNewsletter = allNewsletter.edges.map(edge => edge.node);

  const [query, setQuery] = useQueryParams({
    page: NumberParam,
  });

  const { page: page } = query;
  const { currentPage, setCurrentPage, nextPage: _nextPage, prevPage: _prevPage } = usePagination(page ? page : 1);
  const [visibleItems, setVisibleItems] = React.useState([]);
  const pageSize = 3;

  const onPageChange = e => {
    const page = parseInt(e.target.text, 10);
    setQuery({ page: page });
    setCurrentPage(page);
  };

  const nextPage = () => {
    setQuery({ page: currentPage + 1 });
    _nextPage();
  };

  const prevPage = () => {
    setQuery({ page: currentPage - 1 });
    _prevPage();
  };

  useEffect(() => {
    setVisibleItems(
      allNewsletter.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
      )
    );
  }, [currentPage]);

  return (
    <Layout siteSettings={siteSettings} showBreadcrumbNavigation={showBreadcrumbNavigation} showNews={showNews} >
      <SEO
        title={`${siteSettings.siteName} - ${name}`}
        description={siteSettings.siteDescription}
        author={siteSettings.siteAuthor}
      />
      <Breadcrumb visible={showBreadcrumbNavigation} breadcrumbs={breadcrumbs} locale={node_locale}></Breadcrumb>
      <Container fluid className="pt-5">
        {contentComponents &&
          renderDynamicComponents(contentComponents, node_locale)
        }
        <Row>
          <Col xs={12} md={{ span: 6, offset: 6 }}>
            <Button className={"mb-3"} link={'#footer'} text={getValue("actions.newsletterRegistration", microcopy)} />
          </Col>
        </Row>
      </Container>
      <Container fluid className="newsletterOverview">
        {visibleItems.map((newsletter, i) => (
          <>
          {newsletter.contentful_id === allNewsletter[0].contentful_id ? (
            <div className={"latest-newsletter-label"}>{getValue("labels.latestNewsletter", microcopy)}</div>
          ) : ''}
            <TeaserCard
              className={`mb-3 newsletter ${newsletter.contentful_id === allNewsletter[0].contentful_id ? 'highlighted' : ''}`}
              title={newsletter.number}
              isOverview={true}
              target={"_blank"}
              image={newsletter.impulsArticle.articleDetail.imageSquare.imageSquare.fluid}
              landscape={true}
              content={newsletter.reader ? newsletter.reader.reader: ''}
              link={`/newsletter/${slugify(newsletter.number, SLUGIFY_OPTIONS)}`}
              readMoreLabelText={getValue("actions.toNewsletter", microcopy)}
              linkClass={"button"}
            />
          </>
        ))}
        {allNewsletter.length > pageSize && (
          <Row>
            <Col className="d-flex justify-content-end">
              <PaginationButtons
                itemCount={allNewsletter.length}
                pageSize={pageSize}
                currentPage={currentPage}
                onClick={onPageChange}
                onNext={nextPage}
                onPrev={prevPage}
              />
            </Col>
          </Row>
        )}

      </Container>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($node_locale: String!) {
    allNewsletter: allContentfulNewsletter(filter: { node_locale: { eq: $node_locale } }, sort: { order: DESC, fields: publicationDate }) {
      edges {
        node {
          publicationDate
          contentful_id
          number
          headline
          reader {
            reader
          }
          body {
            body
          }
          impulsArticle {
            articleDetail {
              imageSquare {
                imageSquare {
                  fluid {
                    srcSet
                    src
                    base64
                    srcSetWebp
                    srcWebp
                  }
                }
              }
            }
          }

        }
      }
    }
  }
`;

export default NewsletterOverview;
