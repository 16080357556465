/* Global imports */
import React from "react";
import PropTypes from "prop-types";
import { Pagination } from "react-bootstrap";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/* Local imports */

/* Component definition */
const PaginationButtons = ({
  itemCount,
  pageSize,
  currentPage,
  onClick,
  onPrev,
  onNext,
}) => {
  const pagesAmount = Math.ceil(itemCount / pageSize);
  const pages = generatePagination(currentPage, pagesAmount);

  return (
    <Pagination className="pagination-buttons">
      <Pagination.Item
        className="pagination-button pagination-button--prev"
        onClick={onPrev}
        disabled={currentPage === 1}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </Pagination.Item>
      {pages.map(page => {
        if (page === "...") {
          return <Pagination.Ellipsis className="pagination-button" />;
        }

        return (
          <Pagination.Item
            className="pagination-button"
            key={page}
            active={currentPage === page}
            onClick={onClick}
          >
            {page}
          </Pagination.Item>
        );
      })}
      <Pagination.Item
        className="pagination-button pagination-button--next"
        disabled={currentPage === pagesAmount}
        onClick={onNext}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </Pagination.Item>
    </Pagination>
  );
};

/* PropTypes */
PaginationButtons.propTypes = {
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  itemCount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};
PaginationButtons.defaultProps = {
  currentPage: 1,
  pageSize: 1,
  itemCount: 20,
};

/* Local utility functions */
function generatePagination(current, last) {
  const offset = 2;
  const leftOffset = current - offset;
  const rightOffset = current + offset + 1;

  function reduceToDesiredPageNumbers(accumulator, _, idx) {
    const currIdx = idx + 1;

    if (
      // Always include first page
      currIdx === 1 ||
      // Always include last page
      currIdx === last ||
      // Include if index is between the above defined offsets
      (currIdx >= leftOffset && currIdx < rightOffset)
    ) {
      return [...accumulator, currIdx];
    }

    return accumulator;
  }

  function transformToPagesWithEllipsis(
    accumulator,
    currentPage,
    currIdx,
    src
  ) {
    const prev = src[currIdx - 1];

    // Ignore the first number, as we always want the first page
    // Include an ellipsis if there is a gap of more than one between numbers
    if (prev != null && currentPage - prev !== 1) {
      return [...accumulator, "...", currentPage];
    }

    // If page does not meet above requirement, just add it to the list
    return [...accumulator, currentPage];
  }

  const pageNumbers = Array(last)
    .fill()
    .reduce(reduceToDesiredPageNumbers, []);

  const pageNumbersWithEllipsis = pageNumbers.reduce(
    transformToPagesWithEllipsis,
    []
  );

  return pageNumbersWithEllipsis;
}

/* Styles */

export default PaginationButtons;
